<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="content-container" v-if="startup">
        <div class="page-controller">
          <div class="go-back-div" @click="handleGoBack">
            <div class="">
              <img
                src="/img/onboarding/back-icon.svg"
                style="margin-right: 9px"
              />
            </div>
            <div class="go-back-text">Back to Dashboard</div>
          </div>
          <div class="page-pagination">
            <div
              class="previous-button"
              :class="{ 'disable-toggle': startupIndex == 0 }"
              @click="handleNextProduct('previous')"
            >
              <div>
                <img
                  src="/img/onboarding/back-icon.svg"
                  style="margin-right: 9px"
                />
              </div>
              <div class="button-text">Previous Product</div>
            </div>
            <div
              class="next-button"
              :class="{ 'disable-toggle': startupIndex + 1 == totalStartup }"
              @click="handleNextProduct('next')"
            >
              <div class="button-text">Next Product</div>
              <div>
                <img
                  src="/img/onboarding/forward-icon.svg"
                  style="margin-left: 9px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container-card">
          <div class="startup-name-link">
            <div class="startup-name">
              {{ startup.name ? startup.name : "" }}
            </div>
            <!-- Not implemented yet -->
            <!-- <div class="startup-link">
              <div>
                <img src="/img/onboarding/globe.svg" alt="" />
              </div>
              <div>
                <img src="/img/onboarding/share.svg" alt="" />
              </div>
            </div> -->
          </div>
          <div class="startup-description">
            {{ startup.description }}
          </div>
          <div class="startup-stack-and-industries">INDUSTRY & STACK</div>
          <div class="stack-wrapper">
            <ChipButton
              v-for="(industry, index) in parseToJson(startup.industry)"
              :key="index"
              :text="industry"
              selected="selected"
              :buttonIcon="industry.img"
            />
          </div>
          <div
            class="list-wrapper"
            v-if="startup.stack && startup.stack.length > 0"
          >
            <ChipButton
              v-for="(item, index) in parseToJson(startup.stack)"
              :key="index"
              :text="item"
            />
          </div>
        </div>
        <div class="container-card founders-list-container">
          <div class="founders-and-location">
            <div class="founders-list">
              <div class="section-title">FOUNDERS</div>
              <div
                v-if="startup.founder && startup.founder.name"
                class="founder"
              >
                <div class="linkedin-image">
                  <v-avatar color="indigo" size="36">
                    <span class="white--text text-h5">{{
                      abbrevateName(startup.founder.name)
                    }}</span>
                  </v-avatar>
                </div>
                <div class="linkedin-name">{{ startup.founder.name }}</div>
              </div>
              <!-- <div class="founder">
                <div class="linkedin-image">
                  <img src="/img/dummy/founder2.svg" alt="" />
                </div>
                <div class="linkedin-name">Chineke Adamma</div>
              </div> -->
            </div>
            <!-- <div class="startup-location">
              <div class="section-title">LOCATION</div>
              <div class="current-location">
                <img src="/img/onboarding/location-pin.svg" alt="" />
                San Francisco, CA, USA
              </div>
              <div class="current-location" style="margin-top: 12px">
                <img src="/img/onboarding/time-icon.svg" alt="" />
                GMT +3 (4hrs ahead of your location)
              </div>
            </div> -->
          </div>
          <div class="investors-list">
            <div class="startup-investors">
              <div class="section-title">BACKED BY</div>
              <div class="investor-list">
                <div
                  v-for="(investor, index) in parseToJson(startup.backed_by)"
                  :key="index"
                >
                  <img
                    v-if="
                      investor == 'Y Combinator' ||
                      investor == '500 Startups' ||
                      investor == 'Pear VC'
                    "
                    :src="`/img/dashboard/investors/${investor}.svg`"
                    :alt="investor"
                  />
                  <span v-else>
                    {{ investor }}
                  </span>
                </div>
              </div>
            </div>
            <div class="startup-need" v-if="startup.stage_category_id">
              <div class="section-title">STAGE</div>
              <div class="raised-fund">
                <img src="/img/onboarding/rocket-icon.svg" alt="" />
                {{ parseStage(startup.stage_category_id) }}
              </div>
            </div>
          </div>
        </div>
        <div class="container-card card-margin">
          <div class="startup-compensation-and-need">
            <div class="startup-compensation" v-if="project">
              <div class="section-title">COMPENSATES IN</div>
              <div>
                <img src="/img/dashboard/money-icon.svg" alt="" />
                {{ parseCompensation(project.compensation_id) }}
              </div>

              <div class="section-title" style="margin-top: 48px">
                LOOKING TO START
              </div>
              <div>
                <img src="/img/onboarding/calendar-img.svg" alt="" />
                {{ parseKickoff(project.kick_off) }}
              </div>
            </div>

            <!--  -->
            <div class="startup-employee-need" v-if="project">
              <div class="section-title">LOOKING FOR</div>
              <div class="recruit-list">
                <ChipButton
                  v-for="(l, index) in parseTeamRoles(project.looking_for)"
                  :key="index"
                  backgroundColor="rgba(122, 111, 246, 0.1);"
                  textColor="#7A6FF6"
                  :text="l"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Not implemented yet -->
        <div class="container-card card-margin">
          <div class="call-to-action">
            <div class="join-startup">
              Want to help bring this product to life?
            </div>
            <a
              class="grupa-blue-btn"
              :href="`mailto:matchOps@grupa.io?Subject=Interested in ${startup.name}`"
              >Indicate Interest</a
            >
          </div>
        </div>
        <div class="bottom-pagination">
          <div
            class="previous-button"
            :class="{ 'disable-toggle': startupIndex == 0 }"
            @click="handleNextProduct('previous')"
          >
            <div>
              <img
                src="/img/onboarding/back-icon.svg"
                style="margin-right: 9px"
              />
            </div>
            <div class="button-text">Previous Product</div>
          </div>
          <div
            class="next-button"
            :class="{ 'disable-toggle': startupIndex + 1 == totalStartup }"
            @click="handleNextProduct('next')"
          >
            <div class="button-text">Next Product</div>
            <div>
              <img
                src="/img/onboarding/forward-icon.svg"
                style="margin-left: 9px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import shortName from "@/mixins/shortName";
import appMixin from "@/mixins/appMixin";
import Navbar from "@/components/Navbar/onboarding_navbar";
import ChipButton from "@/components/chipButton/chipButton";

export default {
  mixins: [appMixin, shortName],
  props: {},
  components: {
    Navbar,
    ChipButton,
  },

  data: () => ({}),

  created() {
    console.log("view startup", this.startup);
    if (!this.startup) {
      const startupId = parseInt(this.$route.params.id, 10);
      const startupIndex = this.startupList.findIndex((s) => s.id == startupId);
      if (startupIndex >= 0) {
        this.setStartupIndex(startupIndex);
      }
    }
  },
  methods: {
    ...mapActions("devDashboard", ["setStartupIndex"]),
    handleGoBack() {
      this.$router.push({ name: "dev_dashboard" });
    },
    handleNextProduct(value) {
      const totalStartup = this.startupList.length;
      const index = this.currentStartupIndex;
      let nextIndex = index;
      if (value === "next" && index < totalStartup - 1) {
        nextIndex = index + 1;
        this.setStartupIndex(nextIndex);
      }
      if (value === "previous" && index > 0) {
        nextIndex = index - 1;
        this.setStartupIndex(nextIndex);
      }
      if (nextIndex !== index) {
        this.$router.push({
          name: "view_startup",
          params: { id: this.startupList[nextIndex].id },
        });
      }
    },
    parseTeamRoles(teamRoles) {
      if (!teamRoles) {
        return "";
      }
      const teamRolesObj = {
        1: "Product Designer",
        2: "Software Engineer - Frontend",
        3: "Software Engineer - Backend",
        4: "Software Engineer - FullStack",
        5: "QA Engineer",
        6: "Data Scientist",
        7: "Mobile Engineer - Android",
        8: "Mobile Engineer - IOS",
        9: "DevOps Engineer",
        10: "Product Manager",
      };
      try {
        const r = JSON.parse(teamRoles);
        if (Array.isArray(r)) {
          return r.map((roleId) => teamRolesObj[roleId]);
        }
        return [r];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    parseStage(a) {
      try {
        if (a == null) {
          return "";
        }
        switch (a) {
          case 1:
            return "Angel";
          case 2:
            return "Pre-Seed";
          case 3:
            return "Seed";
          case 4:
            return "Series A";

          default:
            return "";
        }
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    parseKickoff(k) {
      try {
        if (k == null) {
          return "";
        }
        switch (k) {
          case 1:
            return "ASAP";
          case 2:
            return "1 week";
          case 3:
            return "2 weeks";
          case 4:
            return "3 weeks";

          default:
            return "";
        }
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    parseCompensation(comp) {
      if (!comp) {
        return "";
      }
      let text = "";
      try {
        const compensationModels = {
          1: "Cash Only",
          2: "A mix of Cash & Equity",
          3: "Equity only",
          4: `I’m not sure yet`,
        };
        text = compensationModels[comp];
      } catch (error) {
        console.error(error);
      }
      return text;
    },
  },
  computed: {
    ...mapState("devDashboard", ["startupList", "currentStartupIndex"]),
    project() {
      if (!this.startup.projects || !this.startup.projects.length) {
        return null;
      }

      return this.startup.projects[this.startup.projects.length - 1];
    },
    // compensation() {
    //   const comp = parseInt(this.project.compensation_id);
    //   if (!comp) {
    //     return "";
    //   }
    //   let text = "";
    //   try {
    //     const compensationModels = {
    //       1: "Cash Only",
    //       2: "A mix of Cash & Equity",
    //       3: "Equity only",
    //       4: `I’m not sure yet`,
    //     };
    //     text = compensationModels[comp];
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   return text;
    // },
    startup() {
      return this.startupList[this.currentStartupIndex];
    },
    totalStartup() {
      return this.startupList.length;
    },
    startupIndex() {
      return this.currentStartupIndex;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f7f7f8;
  padding-bottom: 100px;
}
.content-container {
  width: 846px;
}
.page-controller {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
}
.go-back-div {
  display: flex;
  cursor: pointer;
}
.go-back-text {
  color: #0055a5;
  font-size: 14px;
  font-family: Moderat;
  font-style: normal;
  font-weight: 500;
  margin-left: 9px;
}
.page-pagination {
  display: flex;
  margin-bottom: 40px;
  gap: 32px;
}
.next-button,
.previous-button {
  display: flex;
  cursor: pointer;
}
.button-text {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  margin-top: 3px;
}
.single-page {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  height: 30px;
  cursor: pointer;
}
.current-page {
  background: #53585f;
  border-radius: 2px;
  color: #ffffff;
}
.container-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 48px;
  text-align: left;
}
.startup-name-link {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}
.startup-name {
  font-family: Moderat;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.startup-link {
  display: flex;
  gap: 24px;
}
.startup-description {
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #979da5;
  margin-top: 24px;
}
.startup-stack-and-industries {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 48px;
}
.stack-wrapper {
  display: flex;
  margin-top: 24px;
  gap: 12px;
}
.list-wrapper {
  display: flex;
  margin-top: 12px;
  gap: 12px;
}
.founders-list-container {
  margin-top: 16px;
}
.investors-list,
.founders-and-location {
  display: flex;
}
.startup-need,
.startup-investors,
.startup-location,
.founders-list {
  width: 50%;
}
.section-title {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-bottom: 8px;
}
.founder {
  display: flex;
  margin-top: 16px;
}
.linkedin-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.current-location {
  margin-top: 16px;

  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
}
.investors-list {
  margin-top: 48px;
}
.investor-list {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}
.raised-fund {
  margin-top: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
}
.startup-compensation-and-need {
  display: flex;
}
.startup-employee-need,
.startup-compensation {
  width: 50%;
}
.card-margin {
  margin-top: 16px;
}
.recruit-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.call-to-action {
  display: flex;
  justify-content: space-between;
}
.join-startup {
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #979da5;
}
.bottom-pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 32px;
}
.disable-toggle {
  opacity: 0.3;
}
</style>
