var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _c("div", { staticClass: "container-fluid" }, [
        _vm.startup
          ? _c("div", { staticClass: "content-container" }, [
              _c("div", { staticClass: "page-controller" }, [
                _c(
                  "div",
                  {
                    staticClass: "go-back-div",
                    on: { click: _vm.handleGoBack },
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "go-back-text" }, [
                      _vm._v("Back to Dashboard"),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "page-pagination" }, [
                  _c(
                    "div",
                    {
                      staticClass: "previous-button",
                      class: { "disable-toggle": _vm.startupIndex == 0 },
                      on: {
                        click: function ($event) {
                          return _vm.handleNextProduct("previous")
                        },
                      },
                    },
                    [
                      _vm._m(1),
                      _c("div", { staticClass: "button-text" }, [
                        _vm._v("Previous Product"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "next-button",
                      class: {
                        "disable-toggle":
                          _vm.startupIndex + 1 == _vm.totalStartup,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleNextProduct("next")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "button-text" }, [
                        _vm._v("Next Product"),
                      ]),
                      _vm._m(2),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "container-card" }, [
                _c("div", { staticClass: "startup-name-link" }, [
                  _c("div", { staticClass: "startup-name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.startup.name ? _vm.startup.name : "") +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "startup-description" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.startup.description) +
                      "\n        "
                  ),
                ]),
                _c("div", { staticClass: "startup-stack-and-industries" }, [
                  _vm._v("INDUSTRY & STACK"),
                ]),
                _c(
                  "div",
                  { staticClass: "stack-wrapper" },
                  _vm._l(
                    _vm.parseToJson(_vm.startup.industry),
                    function (industry, index) {
                      return _c("ChipButton", {
                        key: index,
                        attrs: {
                          text: industry,
                          selected: "selected",
                          buttonIcon: industry.img,
                        },
                      })
                    }
                  ),
                  1
                ),
                _vm.startup.stack && _vm.startup.stack.length > 0
                  ? _c(
                      "div",
                      { staticClass: "list-wrapper" },
                      _vm._l(
                        _vm.parseToJson(_vm.startup.stack),
                        function (item, index) {
                          return _c("ChipButton", {
                            key: index,
                            attrs: { text: item },
                          })
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "container-card founders-list-container" },
                [
                  _c("div", { staticClass: "founders-and-location" }, [
                    _c("div", { staticClass: "founders-list" }, [
                      _c("div", { staticClass: "section-title" }, [
                        _vm._v("FOUNDERS"),
                      ]),
                      _vm.startup.founder && _vm.startup.founder.name
                        ? _c("div", { staticClass: "founder" }, [
                            _c(
                              "div",
                              { staticClass: "linkedin-image" },
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { color: "indigo", size: "36" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "white--text text-h5" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.abbrevateName(
                                              _vm.startup.founder.name
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "linkedin-name" }, [
                              _vm._v(_vm._s(_vm.startup.founder.name)),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "investors-list" }, [
                    _c("div", { staticClass: "startup-investors" }, [
                      _c("div", { staticClass: "section-title" }, [
                        _vm._v("BACKED BY"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "investor-list" },
                        _vm._l(
                          _vm.parseToJson(_vm.startup.backed_by),
                          function (investor, index) {
                            return _c("div", { key: index }, [
                              investor == "Y Combinator" ||
                              investor == "500 Startups" ||
                              investor == "Pear VC"
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        "/img/dashboard/investors/" +
                                        investor +
                                        ".svg",
                                      alt: investor,
                                    },
                                  })
                                : _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(investor) +
                                        "\n                "
                                    ),
                                  ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                    _vm.startup.stage_category_id
                      ? _c("div", { staticClass: "startup-need" }, [
                          _c("div", { staticClass: "section-title" }, [
                            _vm._v("STAGE"),
                          ]),
                          _c("div", { staticClass: "raised-fund" }, [
                            _c("img", {
                              attrs: {
                                src: "/img/onboarding/rocket-icon.svg",
                                alt: "",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.parseStage(_vm.startup.stage_category_id)
                                ) +
                                "\n            "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c("div", { staticClass: "container-card card-margin" }, [
                _c("div", { staticClass: "startup-compensation-and-need" }, [
                  _vm.project
                    ? _c("div", { staticClass: "startup-compensation" }, [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v("COMPENSATES IN"),
                        ]),
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: "/img/dashboard/money-icon.svg",
                              alt: "",
                            },
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.parseCompensation(
                                  _vm.project.compensation_id
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "section-title",
                            staticStyle: { "margin-top": "48px" },
                          },
                          [
                            _vm._v(
                              "\n              LOOKING TO START\n            "
                            ),
                          ]
                        ),
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: "/img/onboarding/calendar-img.svg",
                              alt: "",
                            },
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.parseKickoff(_vm.project.kick_off)) +
                              "\n            "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.project
                    ? _c("div", { staticClass: "startup-employee-need" }, [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v("LOOKING FOR"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "recruit-list" },
                          _vm._l(
                            _vm.parseTeamRoles(_vm.project.looking_for),
                            function (l, index) {
                              return _c("ChipButton", {
                                key: index,
                                attrs: {
                                  backgroundColor: "rgba(122, 111, 246, 0.1);",
                                  textColor: "#7A6FF6",
                                  text: l,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "container-card card-margin" }, [
                _c("div", { staticClass: "call-to-action" }, [
                  _c("div", { staticClass: "join-startup" }, [
                    _vm._v(
                      "\n            Want to help bring this product to life?\n          "
                    ),
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "grupa-blue-btn",
                      attrs: {
                        href:
                          "mailto:matchOps@grupa.io?Subject=Interested in " +
                          _vm.startup.name,
                      },
                    },
                    [_vm._v("Indicate Interest")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "bottom-pagination" }, [
                _c(
                  "div",
                  {
                    staticClass: "previous-button",
                    class: { "disable-toggle": _vm.startupIndex == 0 },
                    on: {
                      click: function ($event) {
                        return _vm.handleNextProduct("previous")
                      },
                    },
                  },
                  [
                    _vm._m(3),
                    _c("div", { staticClass: "button-text" }, [
                      _vm._v("Previous Product"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "next-button",
                    class: {
                      "disable-toggle":
                        _vm.startupIndex + 1 == _vm.totalStartup,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleNextProduct("next")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "button-text" }, [
                      _vm._v("Next Product"),
                    ]),
                    _vm._m(4),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("img", {
        staticStyle: { "margin-right": "9px" },
        attrs: { src: "/img/onboarding/back-icon.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { "margin-right": "9px" },
        attrs: { src: "/img/onboarding/back-icon.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { "margin-left": "9px" },
        attrs: { src: "/img/onboarding/forward-icon.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { "margin-right": "9px" },
        attrs: { src: "/img/onboarding/back-icon.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { "margin-left": "9px" },
        attrs: { src: "/img/onboarding/forward-icon.svg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }