import { render, staticRenderFns } from "./viewStartup.vue?vue&type=template&id=7aef7348&scoped=true&"
import script from "./viewStartup.vue?vue&type=script&lang=js&"
export * from "./viewStartup.vue?vue&type=script&lang=js&"
import style0 from "./viewStartup.vue?vue&type=style&index=0&id=7aef7348&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aef7348",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oeze/Documents/grupa/grupa-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7aef7348')) {
      api.createRecord('7aef7348', component.options)
    } else {
      api.reload('7aef7348', component.options)
    }
    module.hot.accept("./viewStartup.vue?vue&type=template&id=7aef7348&scoped=true&", function () {
      api.rerender('7aef7348', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DevDashboard/overview/vetting/startUps/partials/viewStartup.vue"
export default component.exports